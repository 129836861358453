<template>
  <div class="body">
    <TopNav :alwaysShow="true"></TopNav>
    <div class="fy_center_box">
      <div class="detail">
        <div class="detail-l" v-loading="loading">
          <div class="detail-l-top">
            <div class="detail-l-tit">
              {{datailData.title}}
            </div>
            <div class="detail-l-inf">
              <div>
                <img src="../static/img/inf/top1.png" height="16" width="16"/>
                <span class="detail-l-inf-num">{{ datailData.time }}</span>
<!--                <img src="../static/img/inf/top2.png" height="16" width="16"/>-->
<!--                <span class="detail-l-inf-num">6060</span>-->
<!--                <img src="../static/img/inf/top3.png" height="16" width="16"/>-->
<!--                <span class="detail-l-inf-num">99595</span>-->
              </div>
              <!--              <div>-->
              <!--                <span class="detail-l-inf-num1">分享：</span>-->
              <!--                <img src="../static/img/inf/wechat.png" height="24" width="24"/>-->
              <!--                <img style="margin: 0 10px;" src="../static/img/inf/weibo.png" height="24" width="24"/>-->
              <!--                <img src="../static/img/inf/qq.png" height="24" width="24"/>-->
              <!--              </div>-->

            </div>
          </div>
          <div class="detail-l-mid">
            <div class="cont" v-html="datailData.content"></div>
          </div>
          <div class="detail-l-foot">
<!--            <el-card shadow="always">-->
<!--              <div class="foot-1">-->
<!--                <div class="foot-img1"></div>-->
<!--                <div class="foot-up">上一篇下一篇下一篇下一篇下一篇下一篇下一篇</div>-->
<!--              </div>-->
<!--            </el-card>-->
<!--            <el-card shadow="always">-->
<!--              <div class="foot-2" @click="getNewDetail">-->
<!--                <div class="foot-down">下一篇下一篇下一篇下一篇下一篇下一篇下一篇</div>-->
<!--                <div class="foot-img2"></div>-->
<!--              </div>-->
<!--            </el-card>-->
          </div>

        </div>
        <div class="detail-r"  v-loading="loading">
          <div class="detail-r-top">
            <img src="../static/img/inf/top4.png" height="20" width="20"/>
            <span class="detail-r-top-txt">相关推荐</span>
          </div>
          <div class="detail-r-item" v-for="(p,q) in aboutData"  @click="getNewDetail(p.id)">
            <div class="detail-r-item1">
              {{p.title}}
            </div>
            <div class="detail-r-item2">
              {{p.info}}
            </div>
            <div class="detail-r-item3">
              <div>
                <img src="../static/img/inf/top1.png" height="16" width="16"/>
                <span class="detail-l-inf-num">{{ p.time }}</span>
              </div>
<!--              <div>-->
<!--                <img src="../static/img/inf/top2.png" height="16" width="16"/>-->
<!--                <span class="detail-l-inf-num">6060</span>-->
<!--              </div>-->
<!--              <div>-->
<!--                <img src="../static/img/inf/top3.png" height="16" width="16"/>-->
<!--                <span class="detail-l-inf-num">99595</span>-->
<!--              </div>-->
            </div>

          </div>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import TopNav from '@/components/Nav/TopNav.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'NewsDetail',
  data() {
    return {
      id: 1,
      loading: false,
      datailData: {},
      aboutData:[]
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getDetail(this.id)
  },
  methods: {
    getDetail(id) {
      this.loading = true
      this.$Api.selectNewsContent(id).then(res => {
        this.datailData = res.data
        this.$Api.relevantList({id:id}).then(r => {
          this.loading = false
          this.aboutData = r.data
        }).catch(err => {
        })
      }).catch(err => {
        this.$message.error('服务器开小差！')
      })

    },
    getNewDetail(p){
      this.$router.push({path: '/NewsDetail', query: {id: p}})
    }
  },
  watch: {
    $route(to, from) { //监听路由是否变化
      if (this.$route.query.id) {//判断id是否有值
        console.log(this.$route.query.id)
        this.getDetail(this.$route.query.id);
      }
    }
  },
  components: {
    TopNav,
    Footer
  }
}
</script>
<style lang="scss" scoped>
.body {
  padding-top: 100px;
  background-color: #F8F8F8;

  .title {
    font-size: 28px;
    line-height: 40px;
    color: #0e4794;
    text-align: center;
  }

  .time {
    color: #898989;
    text-align: center;
    line-height: 60px;
  }

  .content {
    width: 100%;
    text-align: left;

    & /deep/ {
      p,
      span,
      ul,
      li,
      strong,
      em,
      div,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: inherit;
        font-family: inherit;
      }
    }
  }
}

.detail {
  width: 1200px;
  display: flex;
  justify-content: left;
  align-items: first;
  margin-bottom: 50px;

  .detail-l {
    width: 800px;
    background-color: white;
    height: 100%;
    margin-right: 20px;
    border-radius: 10px;
    padding: 30px;

    .detail-l-top {
      text-align: left;

      .detail-l-tit {
        font-size: 24px;
        color: #333333;
        font-weight: 500;
        width: 710px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

      }

      .detail-l-inf {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #bbb;
        font-size: 14px;
        margin-top: 20px;

        .detail-l-inf-num {
          position: relative;
          top: -3px;
          left: 0;
          margin-left: 10px;
          margin-right: 20px;
        }

        .detail-l-inf-num1 {
          position: relative;
          top: -5px;
          left: 0;
        }

      }

    }

    .detail-l-mid {

    }

    .detail-l-foot {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .foot-1 {
        background-color: white;
        padding: 10px;
        width: 200px;
        display: flex;
        cursor: pointer;

        .foot-img1 {
          height: 30px;
          width: 23px;
          padding-left: 2px;
          display: inline-block;
          background: url("./../static/img/inf/page-4.png") no-repeat center;
        }

      }

      .foot-2 {
        padding: 10px;
        background-color: white;
        //color: white;
        width: 200px;
        display: flex;
        cursor: pointer;

        .foot-img2 {
          height: 30px;
          padding-right: 2px;
          width: 23px;
          display: inline-block;
          background: url("./../static/img/inf/page-3.png") no-repeat center;
        }
      }

      .foot-1:hover {
        background-color: #AE7B40;
        color: white;

        .foot-img1 {
          background: url("./../static/img/inf/page-1.png") no-repeat center;
        }
      }

      .foot-2:hover {
        background-color: #AE7B40;
        color: white;

        .foot-img2 {
          background: url("./../static/img/inf/page-2.png") no-repeat center;
        }
      }

      .foot-up {
        line-height: 26px;
        width: 200px;
        overflow: hidden;
        line-height: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0 10px;
      }

      .foot-down {
        line-height: 26px;
        margin: 0 10px;
        width: 200px;
        line-height: 30px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

    }


  }

  .detail-r {
    width: 380px;
    background-color: white;
    height: 100%;
    overflow-y: hidden;
    border-radius: 10px;
    text-align: left;
    margin-bottom: 20px;

    .detail-r-top {
      line-height: 40px;
      margin-left: 20px;
      margin-top: 10px;

      .detail-r-top-txt {
        margin-left: 10px;
        color: #AE7B40;
        font-weight: 600;
        position: relative;
        top: -5px;
        left: 0;

      }
    }

    .detail-r-item {
      border-top: 1px solid #ddd;
      padding: 18px 20px;
      transition: 0.5s;

      .detail-r-item1 {
        width: 330px;
        font-size: 18px;
        font-weight: 550;
        color: #333333;
        max-height: 50px;
        transition: 0.5s;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .detail-r-item2 {
        color: #666666;
        font-size: 14px;
        text-indent: 20px;
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        line-height: 23px;
        height: 115px;

      }

      .detail-r-item3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #bbb;
        margin-top: 10px;

        .detail-l-inf-num {
          margin-left: 10px;
          position: relative;
          top: -3px;
          left: 0;
        }

      }
    }

    .detail-r-item:hover {
      background-color: #FFF9F1;
      cursor: pointer;

      .detail-r-item1 {
        color: #AE7B40;
      }
    }
  }
}

/deep/ .el-card__body {
  padding: 0 !important;
}
.cont{
  text-align: left;
}
.cont /deep/ img{
  max-width:100% !important;
}
.cont /deep/ section{
  max-width: 100% !important;
}
</style>
